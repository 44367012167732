#skills-container {
    margin: 0 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    font-family: "Poppins";
}
.heading {
    text-align: center;
    font-size: 75px;
    font-family: "Poppins";
    color: #fff;
    @media screen and (max-width: 768px) {
        font-size: 46px;
    }
}
.skill-card {
    display: flex;
    flex-direction: column;
    padding: 40px 30px;
    border: 3px solid #a3a3a3;

    &:not(:last-child) {
        border-right: none;
    }
    @media screen and (max-width: 768px) {
        & {
            border-right: 3px solid #a3a3a3 !important;
        }
        &:nth-child(3) {
            border-bottom: 3px solid #a3a3a3;
        }
        &:not(:first-child) {
            border-top: none;
        }
    }
    h1 {
        font-size: 26px;
        text-transform: capitalize;
        color: #fff;
        margin: 0;
    }
    .header {
        display: inline-flex;
        gap: 1.5em;
        .card-heading {
            flex-direction: column;
            span {
                color: white;
                font-size: 26px;
                font-weight: 700;
                position: relative;
                &::after {
                    content: "";
                    height: 0.3em;
                    background-color: blue;
                    width: 100%;
                    position: absolute;
                    z-index: -2;
                    bottom: 13.5%;
                    left: -1%;
                }
            }
        }
        svg {
            display: block;
            margin-top: 10px;
            height: 50px;
            width: 50px;
            stroke: white;
            color: white;
        }
    }
    .body-container {
        padding: 20px 30px;
        position: relative;
        &::after {
            opacity: 0.3;
            content: "";
            width: 0;
            height: 60%;
            position: absolute;
            background-color: white;
            border: 0.1px solid white;
            bottom: 19%;
            left: 15px;
        }
    }
    .body {
        color: white;
        font-weight: 400;
        line-height: 1.5em;
        &::before {
            content: "<p>";
            opacity: 0.3;
            color: white;
            display: block;
            margin-left: -30px;
            font-weight: normal;
            font-size: 14px;
        }
        &::after {
            content: "</p>";
            opacity: 0.3;
            color: white;
            display: block;
            margin-left: -30px;
            font-weight: normal;
            font-size: 14px;
        }
    }
}
#expertise {
    margin-top: 5em;
}
