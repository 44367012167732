.new-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2em;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 1em 0;
    backdrop-filter: blur(15px);
    &:has(:hover) .nav-item a:not(:hover) {
        color: #7a7a7a;
    }
    .nav-item a:not(:hover) {
        transition: all 0.5s ease-in-out;
    }
    a {
        color: white;
        text-decoration: none;
        span {
            vertical-align: super;
            font-weight: 100;
            margin-inline-end: 0.75em;
            font-size: 12px;
            color: #aeaeae;
        }
    }
    @media screen and (max-width: 768px) {
        position: fixed;
        inset: 0 0 0 30%;
        flex-direction: column;
        align-items: start;
        padding: 0 3em;
        transform: translateX(100%);
        transition: transform 300ms ease-out;
        a {
            span {
                font-size: 16px;
                color: white;
                vertical-align: unset;
            }
        }
    }
}
.new-navbar[data-visible="true"] {
    transform: translateX(0%);
}
.sticky {
    z-index:10000;
    position: sticky;
    top: 0;
    transition: top 0.5s ease;
    a {
        color: #31dec1;
    }
}
.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-button {
    display: none;
}
@media screen and (max-width: 768px) {
    .menu-button {
        display: block;
        cursor: pointer;
        border-radius: 100%;
        background: #1a2838;
        width: 4em;
        aspect-ratio: 1;
        border: 0;
        position: fixed;
        margin: 2em;
        right: 0.5em;
        z-index: 9999;
        -webkit-tap-highlight-color: transparent;
        &:hover {
            background: #203143;
        }
    }
}
