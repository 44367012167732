#work {
    margin-top: 5em;
}
.work-container {
    margin: 0 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.work-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    flex-basis: 300px;
    background-color: #2d384c;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    .wrapper {
        width: 100%;
        position: relative;
        display: inline-block;
        overflow: hidden;
    }
    .work-img {
        background-image: var(--background);
        background-size: cover;
        width: 100%;
        aspect-ratio: 2/1;
        transition: all 0.5s ease-in-out;
    }
    &:hover {
        @media screen and (min-width: 768px) {
            .work-img {
                transform: scale(1.1);
            }
            .details {
                bottom: 40px;
                opacity: 1;
                transition: bottom 0.5s ease, opacity 0.5s ease;
            }
            .tag {
                position: relative;
                opacity: 0;
                transform: translateY(-30px);
                transition: transform 0.5s ease, opacity 0.5s ease;
            }
        }
    }
    h1 {
        font-size: 26px;
        font-weight: 500;
        color: white;
        margin-left: 10px;
        text-transform: capitalize;
        align-self: start;
        margin-bottom: 0;
    }
    span {
        font-size: 18px;
        color: #aeaeae;
        margin-left: 10px;
        align-self: start;
        margin-bottom: 10px;
        position: relative;
    }

    .details {
        bottom: -100px;
        opacity: 0;
        @media screen and (max-width: 768px) {
            display: none;
            &:hover {
                color: aqua;
            }
        }
    }
}
