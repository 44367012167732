.contact-container {
    display: flex;
    flex-wrap: wrap;
    margin: 5em 30px;
    gap: 10px;
    .form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-basis: 200px;
        flex-grow: 1;
        border-radius: 10px;
        padding: 0.75em 1em;
        // background: #2f174b;
        span {
            font-size: 22px;
            position: relative;
            a {
                position: relative;
                text-decoration: none;
                color: #fff;
            }
        }
        h1 {
            margin: 10px 0;
            font-family: "Poppins";
            color: white;
            font-weight: 600;
        }
        .row {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            gap: 15px;
            margin-top: 1.5em;
            a {
                text-decoration: none;
                font-weight: 500;
                color: white;
                font-family: "Poppins";
                svg {
                    height: 30px;
                    width: 30px;
                    stroke: white;
                }
            }
        }
    }
    .testimonials {
        display: flex;
        flex-grow: 1;
        flex-basis: 800px;
        flex-wrap: wrap;
        .stack{
            // padding: 1em;
            flex-grow: 1;
            flex-basis: 400px;
        }
        .testimonial {
            padding: 1em;
            display: flex;
            flex-basis: 400px;
            flex-direction: column;
            flex-grow: 1;
            gap: 10px;
            border: 1px solid white;
            &::after {
                content: '"';
                color: white;
                font-size: 80px;
                font-family: "Secular One";
                position: absolute;
            }
            .img {
                display: flex;
                height: 50px;
                width: 50px;
                border-radius: 100%;
                border: 2px solid white;
                margin-left: auto;
                margin-top: 10px;
            }
            .content{
                line-height: 2;
                text-align: justify;
            }
            .name {
                margin-left: auto;
                margin-bottom: 0;
            }
            .designation {
                margin-top: 0;
                margin-left: auto;
            }
        }
    }
}
@keyframes easeRight {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 50% 100%;
    }
}
