#hero {
    height: calc(100vh - 60px);
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Poppins";
    color: #fff;
    text-transform: uppercase;
    background: center bottom / cover no-repeat url("../../public/wave.svg");
    text-shadow: 1px 3px 11px rgba(0, 0, 0, 0.3);
    @media screen and (max-width: 768px) {
        height: 100vh;
        padding-top: 5em;
    }
    h1 {
        animation: fadeIn 0.5s ease-in;
        font-size: 128px;
        font-weight: 900;
        margin: 0;
        @media screen and (max-width: 768px) {
            font-size: 46px;
        }
    }
    h2 {
        animation: fadeIn 0.5s ease-in;
        text-align: center;
        font-size: 22px;
        @media screen and (max-width: 768px) {
            font-size: 18px;
            margin: 10px auto;
            letter-spacing: 1.6px;
        }
    }
    #scroll {
        height: 50px;
        width: 30px;
        border: 2px solid black;
        border-radius: 15px;
        position: relative;
        top: 50px;
    }
    #circle {
        height: 8px;
        width: 8px;
        border-radius: 100%;
        background: #000;
        position: relative;
        top: 5px;
        left: 10px;
        animation: move-down 2s infinite;
    }
    @keyframes move-down {
        to {
            opacity: 0;
            top: 35px;
        }
    }
}
