.exp-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.accordion {
    display: flex;
    width: 600px;
    min-width: 0;
    max-width: 100%;
    flex-basis: content;
    justify-content: space-between;
    flex-direction: column;
    padding: 1em 1.5em;
    line-height: 1.5;
    color: #fff;
    font-weight: 600;
    background: #371b58;
    border-radius: 6px;
    .accordion-body {
        display: flex;
        justify-content: space-between;
        div {
            span {
                display: inline-block;
                text-align: right;
                width: 135px;
            }
        }
    }
    .tags-container {
        display: none;
    }
    @media screen and (max-width: 768px) {
        font-size: 14px;
        width: 250px;
        transition: all 0.5s ease;
        .accordion-body > div > span {
            display: none;
        }
    }
    button {
        background: transparent;
        border: none;
        outline: none;
        color: white;
        cursor: pointer;
        margin-left: 10px;
    }
}
.description {
    display: none;
}
.expand-accordion {
    .tags-container {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 80%;
        gap: 10px;
        padding: 1em 0;
        .tag {
            padding: 0.5em 1em;
            // background: #6833a6;
            background: #15212d;
            color: #8a53ca;
            border-radius: 50px;
            font-size: 14px;
            font-family: monospace;
            cursor:default;
        }
        transition: all 0.7s ease;
    }
    .description {
        display: block;
        margin-top: 10px;
        .desc-row {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            span {
                margin-top: 10px;
                font-size: 16px;
                line-height: 1.5;
                flex-basis: 75%;
                text-align: justify;
                font-family: 'Poppins';
                font-weight: normal;
            }
        }
        .location {
            display: inline-flex;
            align-items: center;
            gap: 10px;
            font-family: monospace;
            font-size: 16px;
            text-transform: capitalize;
            // svg {
            //     color: #15212d;
            // }
        }
    }
    transition: all 0.5s ease;
}

.logo {
    width: 100px;
    // mix-blend-mode: multiply;
    @media screen and (max-width: 768px) {
        display: none;
    }
}
