body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 17px;
    background: linear-gradient(to right, #15212e, #121923);
}
* {
    scroll-behavior: smooth;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
@keyframes fadeIn {
    from {
        /* opacity: 0; */
        transform: translateY(30px)
    }
    to {
        /* opacity: 1; */
        transform: translateY(0);
    }
}
@keyframes fadeDown {
    from {
        opacity: 0;
        /* transform: translateY(-30px) */
    }
    to {
        opacity: 1;
        /* transform: translateY(0); */
    }
}
@keyframes fadeLeft {
    from {
        opacity: 0;
        transform: translateX(30px)
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes fadeRight {
    from {
        opacity: 0;
        transform: translateX(-30px)
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.fade-in{
    animation: fadeIn 0.5s ease-in;
}
.fade-left{
    animation: fadeLeft 0.5s ease-in;
}
.fade-right{
    animation: fadeRight 0.5s ease-in;
}
.fade-down{
    animation: fadeDown 0.5s ease-out;
}